<template>
  <div>
    <img style="width:100%" @click="add" src="../../assets/images/market/fxc.png" alt="">
    <el-drawer
      append-to-body
      :visible.sync="drawer"
      size="35%"
      >
      <div slot="title"> 
        <i @click="goback" v-if="drawer==true"> < </i>
        详情
      </div>
      <div class="imgBox">
        <img src="../../assets/images/market/detail2.png" alt="">
      </div>
    </el-drawer>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        drawer: false,
      }
    },
    methods: {
      add() {
        this.drawer = true
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common.scss";
@import "@/assets/scss/theme.scss";

 /deep/#el-drawer__title{ background:#283c4c; padding: 10px;color: $c-white;}
.el-drawer__header{ margin-bottom: 0px;}
.imgBox{
  width: 100%;
  img{ width: 100%; }
}
</style>